import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction } from "../components/CallToAction";
import { FeaturedBlogPostsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";
import { BlogPostItem, Stack } from "../components/Stack";


interface Props {}


const BlogPage: React.FC<Props> = () => {
  const data = useStaticQuery(graphql`
    query {
      blogpage: mdx(
        frontmatter: {
          title: {
            eq: "Blog"
          }
        }
      ) {
        frontmatter {
          pageHeading
          pageDescription
        }
      }

      articles: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "article"
            }
          }
        }
        sort: {
          fields: [frontmatter___postDate]
          order: DESC
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              postDate(
                formatString: "DD MMM YYYY"
              )
              heroImage {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              featured
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Blog"
      />

      <SectionWrapper
        background={{
          base: 'linear-gradient(#FBF7F1 0%, #FBF7F1 55%, white 55%, white 100%)',
          lg: 'linear-gradient(#FBF7F1 0%, #FBF7F1 65%, white 65%, white 100%)'
        }}
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            heading={ data.blogpage.frontmatter.pageHeading }
            description={ data.blogpage.frontmatter.pageDescription }
          >
            <FeaturedBlogPostsCarousel
              nodes={ data.articles.edges }
            />
          </PageHero>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0 65px"
      >
        <SectionContainer>
          <Stack
            dividerVisible
            align="flex-start"
          >
            { data.articles.edges.map(( article: any, _index: number ) => (
              <BlogPostItem
                key={ _index }
                heading={ article.node.frontmatter.title }
                description={ article.node.frontmatter.title }
                postDate={ article.node.frontmatter.postDate }
                slug={ article.node.fields.slug }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default BlogPage;
